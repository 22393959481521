.reward input[type="radio"] {
    margin: 0px !important;
  }
  .reward input[type="radio"] {
    width: 100%;
    outline: none;
    margin-bottom: 2px;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
  }
  form.form-outer label {
    width: 100%;
    text-transform: inherit;
  }
  .reward img {
    width: 100%;
  }
  .reward label {
    min-height: 64px !important;
  }
  .imgcolor {
    margin-top: 10px;
    width: 100%;
    border-radius: 6px;
    margin-right: 8px;
  }
  .carousel-inner>.item>a>img, .carousel-inner>.item>img, .img-responsive, .thumbnail a>img, .thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto;
  }